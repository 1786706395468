<script setup lang="ts">
import { computed } from 'vue'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import Youtube from '@tiptap/extension-youtube'
import { generateHTML } from '@tiptap/html'

import type { VoixWysiwygFieldInterface } from '@voix/types'

const props = defineProps<{
  field: VoixWysiwygFieldInterface
}>()

const output = computed(() => {
  if (!props.field.value)
    return null

  if (props.field.value && typeof props.field.value === 'object')
    return generateHTML(props.field.value, [StarterKit, Underline, Youtube])

  return props.field.value
})
</script>

<template>
  <div v-if="field.enabled && output">
    <div v-html="output" />
  </div>
</template>
