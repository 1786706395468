<script setup lang="ts">
import { computed, useSlots } from 'vue'
import type { PropType } from 'vue'
import type { VoixLinkFieldInterface } from '../fields/link/types'

const props = defineProps({
  field: {
    type: Object as PropType<VoixLinkFieldInterface>,
    default: null,
  },
})

const slots = useSlots()

const hasSlot = slots.default !== undefined

const isEnabled = computed(() => {
  if (typeof props.field.enabled !== 'undefined')
    return props.field.enabled

  return true
})
</script>

<template>
  <NuxtLink
    v-if="field && field.value && isEnabled"
    :to="field.value.href"
    :target="field.value.target"
    :rel="field.value.relationship"
  >
    <slot v-if="hasSlot" :text="field.value.text" />
    <template v-else>
      {{ field.value.text }}
    </template>
  </NuxtLink>
</template>
